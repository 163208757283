import { useQuery } from '@tanstack/react-query'
import { Employee } from 'employee/types/employees'
import httpClient from 'utils/http-client'

const EMPLOYEE_KEY = ['employee']

type EmployeeShowResponse = {
  id: string
  email: string
  first_name: string
  last_name: string
}

const PLACEHOLDER_DATA = {
  id: '',
  email: '',
  firstName: '',
  lastName: '',
}

export const useEmployee = () => {
  const { data, ...rest } = useQuery<Employee>({
    queryKey: EMPLOYEE_KEY,
    queryFn: () =>
      httpClient<EmployeeShowResponse>('/employee').then(({ id, email, first_name, last_name }) => ({
        id,
        email,
        firstName: first_name,
        lastName: last_name,
      })),
    placeholderData: PLACEHOLDER_DATA,
    staleTime: 30 * 60 * 1000, // 30 minutes
  })

  return { data: data ?? PLACEHOLDER_DATA, ...rest }
}
