import Button from 'components/Button'
import Divider from 'components/Divider'
import CheckBox from 'components/CheckBox'
import Text from 'components/Text'
import { useMarkDocumentsAsNotOwned } from 'employee/queries/not_owned_documents'
import { DocumentGroup } from 'employee/types/document_groups'
import i18next from 'i18next'
import React from 'react'
import toast from 'react-hot-toast'
import Drawer from 'employee/components/Drawer'

type NotOwnedDocumentDrawerProps = {
  documentGroups: DocumentGroup[]
  onClose: () => void
}

const NotOwnedDocumentDrawer = ({ documentGroups, onClose }: NotOwnedDocumentDrawerProps) => {
  const [selectedDocumentGroupsIds, setSelectDocumentGroupsIds] = React.useState<string[]>([])

  const { mutate: markDocumentAsNotOwned, isPending } = useMarkDocumentsAsNotOwned({ onSuccess: onClose })

  const onCheckedChange = (documentGroupId: string) => (checked: boolean) => {
    if (checked) {
      setSelectDocumentGroupsIds([...selectedDocumentGroupsIds, documentGroupId])
    } else {
      setSelectDocumentGroupsIds(selectedDocumentGroupsIds.filter((id) => id !== documentGroupId))
    }
  }

  const handleSubmit = () => {
    if (selectedDocumentGroupsIds.length === 0) {
      toast.error(i18next.t('employee.document_groups.not_owned_documents_drawer.error_no_document_group'))
      return
    }
    markDocumentAsNotOwned({ documentGroupIds: selectedDocumentGroupsIds })
  }

  return (
    <Drawer onRequestClose={onClose}>
      <Text type="title" className="text-xl">
        {i18next.t('employee.document_groups.not_owned_documents_drawer.title')}
      </Text>
      <Text type="subtext" className="mt-1">
        {i18next.t('employee.document_groups.not_owned_documents_drawer.subtitle')}
      </Text>
      <Divider className="my-6" />

      <form
        onSubmit={(e) => {
          e.preventDefault()
          handleSubmit()
        }}
      >
        {documentGroups.map((documentGroup: DocumentGroup) => {
          const isChecked = selectedDocumentGroupsIds.includes(documentGroup.id)
          const checkBoxKey = isChecked
            ? 'employee.document_groups.not_owned_documents_drawer.unselect_document_group'
            : 'employee.document_groups.not_owned_documents_drawer.select_document_group'
          return (
            <CheckBox
              name={i18next.t(checkBoxKey, { label: documentGroup.label })}
              key={documentGroup.id}
              label={documentGroup.label}
              checked={isChecked}
              onChange={onCheckedChange(documentGroup.id)}
              containerClassName="mb-3"
            />
          )
        })}
        <Button
          type="submit"
          name={i18next.t('employee.document_groups.not_owned_documents_drawer.button')}
          disabled={selectedDocumentGroupsIds.length === 0}
          className="w-full mt-6"
          loading={isPending}
        >
          {i18next.t('employee.document_groups.not_owned_documents_drawer.button')}
        </Button>
      </form>
    </Drawer>
  )
}

export default NotOwnedDocumentDrawer
