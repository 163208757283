import React from 'react'
import i18next from 'i18next'
import Divider from 'components/Divider'
import ClickableDiv from 'components/ClickableDiv'
import Text from 'components/Text'
import Icon from 'components/Icon'
import Drawer from 'employee/components/Drawer'

type BackImageSelectionDrawerProps = {
  onFileSelect: () => void
}

const BackImageSelectionDrawer = ({ onFileSelect }: BackImageSelectionDrawerProps) => (
  <Drawer>
    <Text type="title" className="text-xl">
      {i18next.t('employee.document_groups.back_image_drawer.title')}
    </Text>
    <Text type="subtext" className="mt-1">
      {i18next.t('employee.document_groups.back_image_drawer.subtitle')}
    </Text>
    <Divider className="my-6" />

    <ClickableDiv label="existing" onClick={onFileSelect} className="flex items-center gap-3">
      <Icon name="filter_center_focus" className="w-8 h-8" />
      <Text type="text">{i18next.t('employee.document_groups.upload_method_drawer.take_picture')}</Text>
    </ClickableDiv>
  </Drawer>
)

export default BackImageSelectionDrawer
