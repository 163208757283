import classNames from 'classnames'
import React from 'react'
import ReactModal from 'react-modal'

type DrawerProps = {
  onRequestClose?: () => void
  className?: string
  overlayClassName?: string
}

const Drawer = ({ onRequestClose, className, overlayClassName, children }: React.PropsWithChildren<DrawerProps>) => (
  <ReactModal
    isOpen
    overlayClassName={classNames('fixed inset-0 bg-modalBackrop flex justify-center items-end', overlayClassName)}
    className={classNames('px-6 pt-8 pb-12 bg-white rounded-t-md w-full', className)}
    onRequestClose={onRequestClose}
  >
    {children}
  </ReactModal>
)

export default Drawer
