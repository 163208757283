import React, { useState } from 'react'
import i18next from 'i18next'
import Button from 'components/Button'
import { OnChangeFormFunction } from 'employee/types/signup'
import PasswordCreationInput from 'components/PasswordCreationInput'
import { trackEvent } from 'utils/amplitude'
import Header from './Header'

type PasswordProps = {
  value: string
  onChange: OnChangeFormFunction
  onNext: () => void
}

const Password = ({ value, onChange, onNext }: PasswordProps) => {
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)
  const [loading, setLoading] = useState(false)

  const buttonDisabled = !value || value.length < window.minimum_password_length || loading || !!errorMessage
  return (
    <form
      onSubmit={(event) => {
        event.preventDefault()
        trackEvent('signup-password-click')
        onNext()
      }}
      className="grow flex flex-col"
    >
      <div className="w-full flex flex-col grow pt-12">
        <Header
          icon="key"
          title={i18next.t(`employee.signup.password.title`)}
          subtitle={i18next.t(`employee.signup.password.subtitle`)}
        />

        <PasswordCreationInput
          className="w-full"
          label={i18next.t('employee.signup.password.label')}
          value={value}
          onChange={(newValue) => onChange('password', newValue)}
          errorMessage={errorMessage}
          setErrorMessage={setErrorMessage}
          setLoading={setLoading}
        />
      </div>
      <Button type="submit" name="next" className="my-6 w-full" disabled={buttonDisabled}>
        {i18next.t('common.continue')}
      </Button>
    </form>
  )
}

export default Password
