import React from 'react'
import i18next from 'i18next'
import Divider from 'components/Divider'
import ClickableDiv from 'components/ClickableDiv'
import Text from 'components/Text'
import Icon from 'components/Icon'
import Drawer from 'employee/components/Drawer'

type DoubleSidedConfirmationDrawerProps = {
  onClose: () => void
  onBackImageSelect: () => void
}

const DoubleSidedConfirmationDrawer = ({ onClose, onBackImageSelect }: DoubleSidedConfirmationDrawerProps) => (
  <Drawer>
    <Text type="title" className="text-xl">
      {i18next.t('employee.document_groups.double_sided_confirmation_modal.title')}
    </Text>
    <Text type="subtext" className="mt-1">
      {i18next.t('employee.document_groups.double_sided_confirmation_modal.subtitle')}
    </Text>
    <Divider className="my-6" />

    <ClickableDiv label="existing" onClick={onBackImageSelect} className="flex items-center gap-3">
      <Icon name="file_present" className="w-8 h-8" />
      <Text type="text">{i18next.t('employee.document_groups.double_sided_confirmation_modal.upload_back_image')}</Text>
    </ClickableDiv>

    <ClickableDiv label="cancel" onClick={onClose} className="flex items-center gap-3 mt-6">
      <Icon name="task" className="w-8 h-8" />
      <Text type="text">{i18next.t('employee.document_groups.double_sided_confirmation_modal.cancel')}</Text>
    </ClickableDiv>
  </Drawer>
)

export default DoubleSidedConfirmationDrawer
