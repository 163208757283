import { useMutation } from '@tanstack/react-query'
import httpClient from 'utils/http-client'
import { useInvalidateDocumentGroupsQuery } from './document_groups'

type MutationArgs = { onSuccess?: () => void; onError?: (error: Error) => void }

type UseMarkDocumentAsNotOwnedMutationParams = { documentGroupId: string }

export const useMarkDocumentAsNotOwned = ({ onSuccess, onError }: MutationArgs = {}) =>
  useMutation({
    mutationFn: async ({ documentGroupId }: UseMarkDocumentAsNotOwnedMutationParams) =>
      httpClient(`/document_groups/${documentGroupId}/not_owned_documents`, { method: 'post' }),
    onSuccess,
    onError,
  })

type UseMarkDocumentsAsNotOwnedMutationParams = { documentGroupIds: string[] }

export const useMarkDocumentsAsNotOwned = ({ onSuccess, onError }: MutationArgs = {}) => {
  const invalidateDocumentGroupQuery = useInvalidateDocumentGroupsQuery()

  return useMutation({
    mutationFn: async ({ documentGroupIds }: UseMarkDocumentsAsNotOwnedMutationParams) =>
      httpClient('/not_owned_documents', { method: 'post', body: { document_groups_ids: documentGroupIds } }),
    onSuccess: () => {
      invalidateDocumentGroupQuery()
      if (onSuccess) onSuccess()
    },
    onError,
  })
}
