import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'
import i18next from 'i18next'
import Icon from 'components/Icon'
import Text from 'components/Text'
import Button from 'components/Button'
import { TDocumentGroupWithExpiration } from 'employee/types/document_groups'
import DocumentChoiceRow from 'employee/components/document_groups/DocumentChoiceRow'
import DocumentUploadFlow from 'employee/components/document_groups/DocumentUploadFlow'
import { EmployeeUploadedDocument } from 'employee/types/documents'
import { showNotOwnedDocumentButton, trackNotOwnedEvent } from 'employee/utils/not_owned_documents'
import { useMarkDocumentAsNotOwned } from 'employee/queries/not_owned_documents'
import { sendError } from 'utils/appsignal'
import toast from 'react-hot-toast'

type UploadedDocumentListProps = {
  documentGroup: TDocumentGroupWithExpiration
  slug: string
}

const UploadedDocumentList = ({ slug, documentGroup }: UploadedDocumentListProps) => {
  const [uploadDocument, setUploadDocument] = useState<boolean>(false)
  const navigate = useNavigate()

  const { mutate: markDocumentAsNotOwned } = useMarkDocumentAsNotOwned({
    onSuccess: () => {
      navigate('/document_groups')
    },
    onError: (error: Error) => {
      sendError(error)
      navigate('/document_groups')
      toast.error(i18next.t('common.unexpected_error'))
    },
  })

  const onDocumentClick = (documentSlug: string, document: EmployeeUploadedDocument) => {
    navigate(`/document_groups/${documentGroup.id}/${documentSlug}?documentId=${document.id}`)
  }

  const onNotOwnedDocumentClick = () => {
    trackNotOwnedEvent(documentGroup.slug)
    markDocumentAsNotOwned({ documentGroupId: documentGroup.id })
  }

  const onFinishClick = () => {
    navigate(-1)
  }

  return (
    <div className="flex flex-col grow">
      <div className="text-center mb-10">
        <Text type="title">{i18next.t(`document_group_config.${slug}_title`)}</Text>
        <Text type="subtext">{i18next.t(`document_group_config.${slug}_subtitle`)}</Text>
      </div>
      <div className="flex grow flex-col gap-y-3 overflow-auto grow">
        {Object.keys(documentGroup.documents).map((documentKey) =>
          (documentGroup.documents[documentKey] || []).map((document) => (
            <DocumentChoiceRow
              key={document.id}
              label={document.label}
              status={document.status_for_employee}
              onClick={() => onDocumentClick(documentKey, document)}
              displayStatusTag
              displayIcon={false}
            />
          ))
        )}
        <Button
          type="button"
          uiStyle="secondary"
          name={i18next.t('document_groups.actions.add_a_file')}
          onClick={() => {
            setUploadDocument(true)
          }}
          className="flex flex-row items-center justify-center gap-2"
        >
          <Icon name="add" />
          {i18next.t('document_groups.actions.add_a_file')}
        </Button>
        {showNotOwnedDocumentButton({ documentGroup, page: 'uploaded_document_list' }) && (
          <Button
            type="button"
            uiStyle="secondary"
            name={i18next.t('employee.document_groups.upload_method_drawer.choose_not_owned_document')}
            onClick={onNotOwnedDocumentClick}
            className="flex flex-row items-center justify-center gap-2"
          >
            <Icon name="close" />
            {i18next.t('employee.document_groups.upload_method_drawer.choose_not_owned_document')}
          </Button>
        )}
      </div>

      <Button type="button" name={i18next.t('common.finish')} onClick={onFinishClick} className="mt-3 shrink-0">
        {i18next.t('common.finish')}
      </Button>

      {uploadDocument ? (
        <DocumentUploadFlow
          slug={slug}
          documentGroupId={documentGroup.id}
          onClose={() => setUploadDocument(false)}
          onFinish={() => setUploadDocument(false)}
        />
      ) : null}
    </div>
  )
}

export default UploadedDocumentList
