import React, { useMemo } from 'react'
import i18next from 'i18next'
import { useNavigate } from 'react-router-dom'

import { useDocumentGroups } from 'employee/queries/document_groups'
import Text from 'components/Text'
import { trackEvent } from 'utils/amplitude'
import { DocumentGroup } from 'employee/types/document_groups'
import DocumentUploadFlow from 'employee/components/document_groups/DocumentUploadFlow'
import Category from 'employee/components/document_groups/DocumentGroupIndex/Category'
import { useDocumentAdvice } from 'employee/hooks/useDocumentAdvice'
import Button from 'components/Button'
import NotOwnedDocumentDrawer from 'employee/components/document_groups/NotOwnedDocumentDrawer'

const DocumentGroupIndex = () => {
  const [selectDocumentGroup, setSelectDocumentGroup] = React.useState<DocumentGroup | null>()
  const [showNotOwnedDocumentDrawer, setShowNotOwnedDocumentDrawer] = React.useState(false)
  const navigate = useNavigate()
  const { data: documentGroups } = useDocumentGroups()
  const { AdviceComponent, showAdvice, setShowAdviceIfNeeded, hideAdvice } = useDocumentAdvice()

  const onDocumentClick = (status: string) => (documentGroup: DocumentGroup) => {
    trackEvent('document-group-click', {
      documentGroupId: documentGroup.id,
      documentGroupSlug: documentGroup.slug,
    })

    const documentGroupDocumentPath = `/document_groups/${documentGroup.id}/${documentGroup.slug}`
    const documentGroupListPath = `/document_groups/${documentGroup.id}/${documentGroup.slug}/list`
    const documentGroupHabilitationPath = `/document_groups/${documentGroup.id}/${documentGroup.slug}/folder`

    if (documentGroup.kind === 'list') {
      navigate(documentGroupListPath)
    } else if (documentGroup.kind === 'folder') {
      navigate(documentGroupHabilitationPath)
    } else if (status === 'valid') {
      navigate(documentGroupDocumentPath)
    } else {
      setSelectDocumentGroup(documentGroup)
      setShowAdviceIfNeeded(documentGroup.slug)
    }
  }

  const needsActionNotMandatoryDocumentGroups = useMemo(
    () => documentGroups?.needsAction.filter((documentGroup) => documentGroup.allowBlank) ?? [],
    [documentGroups?.needsAction.length]
  )

  if (!documentGroups) {
    return null
  }

  return (
    <div className="flex flex-col h-full px-6 pt-12 pb-2">
      {showAdvice && (
        <AdviceComponent
          onContinueClick={hideAdvice}
          onClose={() => {
            hideAdvice()
            setSelectDocumentGroup(null)
          }}
        />
      )}
      {!showAdvice && selectDocumentGroup ? (
        <DocumentUploadFlow
          slug={selectDocumentGroup.slug}
          documentGroupId={selectDocumentGroup.id}
          onClose={() => setSelectDocumentGroup(null)}
          onFinish={() => setSelectDocumentGroup(null)}
        />
      ) : null}
      <div>
        <Text type="title">{i18next.t('employee.document_groups.title')}</Text>
        {documentGroups.needsAction.length > 0 ? (
          <Text type="subtext" className="pt-1 pb-4">
            <span
              // eslint-disable-next-line react/no-danger
              dangerouslySetInnerHTML={{
                __html: i18next.t('employee.document_groups.needs_action_subtitle', {
                  count: documentGroups.needsAction.length,
                }),
              }}
            />
          </Text>
        ) : null}
      </div>
      {documentGroups.needsAction.length > 0 ? (
        <>
          <Category
            id="needsActionDocumentGroups"
            title={i18next.t('employee.document_groups.needs_action_section')}
            documentGroups={documentGroups.needsAction}
            onClick={onDocumentClick('missing')}
          />
          {needsActionNotMandatoryDocumentGroups.length > 0 && (
            <Button
              uiStyle="secondary"
              name={i18next.t('employee.document_groups.not_owned_button')}
              onClick={() => {
                trackEvent('not-owned-documents-click')
                setShowNotOwnedDocumentDrawer(true)
              }}
              className="mb-4"
            >
              {i18next.t('employee.document_groups.not_owned_button')}
            </Button>
          )}
          {showNotOwnedDocumentDrawer && (
            <NotOwnedDocumentDrawer
              documentGroups={needsActionNotMandatoryDocumentGroups}
              onClose={() => setShowNotOwnedDocumentDrawer(false)}
            />
          )}
        </>
      ) : null}
      {documentGroups.notOwned.length > 0 ? (
        <Category
          id="notOwnedDocumentGroups"
          title={i18next.t('employee.document_groups.not_owned_section')}
          documentGroups={documentGroups.notOwned}
          onClick={onDocumentClick('not_owned')}
        />
      ) : null}
      {documentGroups.ok.length > 0 ? (
        <Category
          id="validDocumentGroups"
          title={i18next.t('employee.document_groups.valid_section')}
          documentGroups={documentGroups.ok}
          onClick={onDocumentClick('valid')}
        />
      ) : null}
    </div>
  )
}

export default DocumentGroupIndex
